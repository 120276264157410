import { createContext, useEffect, useRef, useState } from "react";
import "./App.css";
import { ActionBar } from "./components/ui/ActionBar";
import { Chat } from "./components/ui/Chat";
import MenuBar from "./components/ui/MenuBar";
import { Table } from "./components/game-play/Table";
import { GameProvider } from "./contexts/Game";
import { getSocket } from "./socket/socket";
import { Logout } from "./components/ui/Logout";
import BouncingDotsLoader from './components/BouncingDotsLoader';
import axios from 'axios';
import background from './assets/texture/background.png';
import HowToPlayPokerModal from './components/ui/HowToPlayPokerModal';

export const AppContext = createContext({});

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [onlyWatch, setOnlyWatch] = useState(false);
  const [socket, setSocket] = useState(null);
  const [chatHidden, setChatHidden] = useState(true);
  const [faqHidden, setFAQHidden] = useState(false);
  const [chatHint, setChatHint] = useState("- Press T to chat -");
  const [showLogout, setShowLogout] = useState(false);
  const [tryToLogout, setTryToLogout] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const containerRef = useRef(null);

  const login = (userData, address, onFail = () => {}) => {
    address = address || "poker-server.good-dogs.io";
    const socket = getSocket(userData.name || userData.user_id, userData.user_id, userData.room_id, address);
    socket.on("connect", () => {
      const coolDogJwt = localStorage.getItem('cool-dog_jwt');
      socket.emit('join', { ...userData, 'cool-dog_jwt': coolDogJwt })
    });
    socket.on('join_success', (token) => {
      localStorage.setItem('cool-dog_jwt', token);
      setLoggedIn(true);
    })
    socket.on("connect_error", () => {
      alert("Can't connect to server!");
      setLoggedIn(false);
    });
    socket.on("redirect_to_lobby", (data) => {
      if (data.redirectToLobby) {
        socket.disconnect();
        setTryToLogout(true);
        /*eslint no-restricted-globals: ["error", "event"]*/
        setTimeout(() => {
          window.location.replace(userData.return_url || 'https://good-dogs.io/games/poker/dogs')
        }, 2000)
      }
    })
    socket.on("error", (error) => {
      console.log(error, 'error')
      if (error.type === 101) {
        setLoggedIn(false);
        setOnlyWatch(true);
      }

      if (error.type === 102) {
        setLoggedIn(true)
      }

      if (error.type === 403) {

      }
      switch (error.type) {
        case 101:
          setLoggedIn(false);
          setOnlyWatch(true);
          break;
        case 102:
          setLoggedIn(true);
          break;
        case 403:
              socket.disconnect();
              setTryToLogout(true);
              setLoggedIn(false);
              setOnlyWatch(false);
              /*eslint no-restricted-globals: ["error", "event"]*/
              setTimeout(() => {
                window.location.replace(userData.return_url || 'https://good-dogs.io/games/poker/dogs')
              }, 2000)
          break;
        case 404:
              socket.disconnect();
              setTryToLogout(true);
              setLoggedIn(false);
              setOnlyWatch(false);
              /*eslint no-restricted-globals: ["error", "event"]*/
              setTimeout(() => {
                window.location.replace(userData.return_url || 'https://good-dogs.io/games/poker/dogs')
              }, 2000)
          break;
        default:
              socket.disconnect();
              setTryToLogout(true);
              setLoggedIn(false);
              setOnlyWatch(false);
              /*eslint no-restricted-globals: ["error", "event"]*/
              setTimeout(() => {
                window.location.replace(userData.return_url || 'https://good-dogs.io/games/poker/dogs')
              }, 2000)
          break;
      }
    });
    socket.on("disconnect", () => {
      setTryToLogout(true);
      setOnlyWatch(false);
      setLoggedIn(false);
    });
    setSocket(socket);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const hash = queryParams.get('hash');

    if (hash && !loggedIn && !tryToLogout) {
      const request = () => {
        axios.post(`/api/join`, { hash })
          .then(({ data }) => {
            setCurrentUser(data);
            if (!onlyWatch) login(data);
          })
          .catch ((err) => {
            console.log(err, "err")
            if (err.status === 404 || err.response.status === 404 || err.status === 403 || err.response.status === 403) {
              socket.disconnect();
              setTryToLogout(true);
              /*eslint no-restricted-globals: ["error", "event"]*/
              setTimeout(() => {
                window.location.replace(currentUser.return_url || 'https://good-dogs.io/games/poker/dogs')
              }, 2000)
              return
            }
            setTimeout(request, 3000)
          })
      }

      request();
    }
  }, [loggedIn]);

  useEffect(() => {
    const keyDown = (e) => {
      if (!loggedIn) return;
      if (e.key === "t" || e.key === "T" || e.key === "`") {
        setChatHidden(false);
      }
    };
    document.addEventListener("keydown", keyDown);
    return () => {
      document.removeEventListener("keydown", keyDown);
    };
  }, [loggedIn, chatHidden]);

  useEffect(() => {
    if (chatHidden) containerRef?.current?.focus();
    else setChatHint("");
  }, [chatHidden]);

  return (
    <AppContext.Provider
      value={{
        socket,
        chatHidden,
        chatHint,
        showLogout,
        setShowLogout,
        currentUser,
        setTryToLogout
      }}
    >
      <div
        className="relative h-screen"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "100% 100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat"
        }}
      >
        { tryToLogout && <BouncingDotsLoader className="mt-5" text='Disconnection' /> }
        { (!loggedIn && !onlyWatch && !tryToLogout) && <BouncingDotsLoader className="mt-5" text='Search for an available room' /> }
        {(loggedIn || onlyWatch) && (
          <GameProvider>
            <div className="absolute w-full h-full justify-center items-center flex pb-28">
              <Table />
            </div>
            <div className="absolute w-full h-full flex flex-col justify-end pointer-events-none">
              <ActionBar />
            </div>
            <div className="absolute w-full h-full flex flex-col justify-end pointer-events-none">
              <MenuBar
                toggleChat={() => setChatHidden((hid) => !hid)}
                toggleFAQ={() => setFAQHidden((hid) => !hid)}
              />
            </div>
            {faqHidden && <HowToPlayPokerModal toggleFAQ={() => setFAQHidden((hid) => !hid)} />}
            <div className="absolute w-full h-full flex flex-col justify-center items-center pointer-events-none">
              {chatHint && (
                <div className="text-black opacity-30 tracking-wider uppercase text-2xl absolute top-4 text-center">
                  {chatHint}
                </div>
              )}
              <Chat hidden={chatHidden} setHidden={setChatHidden} />
            </div>
            <div className="absolute w-full h-full flex flex-col justify-end pointer-events-none">
              <Logout returnUrl={currentUser.return_url} />
            </div>
          </GameProvider>
        )}
      </div>
    </AppContext.Provider>
  );
}

export default App;
