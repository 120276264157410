import React from "react";
import howToPlayPoker from "./../../assets/texture/how-to-play-poker.jpg"

export default function HowToPlayPokerModal({ toggleFAQ }) {
  const toggleFAQFunc = (e) => {
    if (e.target.id === 'defaultModal') toggleFAQ()
  }
  return (
    <div onClick={toggleFAQFunc} id="defaultModal" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
      <div className="relative p-4 w-full max-w-2xl h-full md:h-auto m-auto">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div onClick={toggleFAQ} className="close-btn cursor-pointer">
            <div className="wrapper">
              <div className="arrow">
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </div>
          </div>
          <div className="p-6 space-y-6">
            <img alt='how to play poker' src={howToPlayPoker} />
          </div>
        </div>
      </div>
    </div>
  );
}
