import React, { useContext } from "react";
import {
  AiFillWechat,
  AiOutlineLogout,
  AiFillQuestionCircle,
} from "react-icons/ai";
import { AppContext } from "../../App.jsx";

export default function MenuBar({
  toggleChat,
  toggleFAQ
}) {
  const { setShowLogout } = useContext(AppContext);
  function handleAction(actionFun) {
    return () => {
      actionFun();
    };
  }
  return (
    <div className="absolute overflow-visible top-0 w-full text-slate-700 text-4xl pointer-events-auto flex flex-row items-center justify-between">
      <div
        className="rotate-180 p-4 w-fit text-gray-500 hover:text-white cursor-pointer transition duration-100"
        title="Log Out"
        onClick={handleAction(() => setShowLogout((s) => !s))}
      >
        <AiOutlineLogout />
      </div>
      <div className="flex-1"></div>
      <div
        className="p-4 w-fit text-gray-500 hover:text-white cursor-pointer transition duration-100"
        title="Info"
        onClick={handleAction(toggleFAQ)}
      >
        <AiFillQuestionCircle />
      </div>
      <div
        className="p-4 w-fit text-gray-500 hover:text-white cursor-pointer transition duration-100"
        title="Chat"
        onClick={handleAction(toggleChat)}
      >
        <AiFillWechat />
      </div>
    </div>
  );
}
