import React from "react";
import { useGame } from "../../contexts/Game";
import cardBack from "../../assets/texture/card-back.png";
import { cardUrls } from './../../utilities/card';
import { AiFillCheckCircle } from "react-icons/ai";

export function Card({ isSleeve, isActive, index, card, hidden, onClick, className }) {
  const { winners } = useGame();
  const hands = winners.map((winner) => winner.cards);
  const showDown = winners.length > 0;
  const highLight =
    card && !isSleeve &&
    hands.some((hand) =>
      hand.some((c) => c.suit === card.suit && c.value === card.value)
    );

  const suitMap = {
    "♠": 'SPADE',
    "♦": 'DIAMOND',
    "♣": 'CLUB',
    "♥": 'HEART',
  }
  return (
    <div
      className={
        "transition duration-500 -mx-1 flex justify-center items-center select-none rounded-lg relative" +
        (isActive ? " card-active" : "") +
        (showDown ? (highLight ? " -translate-y-5" : " ") : " ") +
        ` ${className}`
      }
      onClick={(e) => (onClick ? onClick(e, {...card, index}) : false)}
    >
      {isActive && (
        <div className='absolute w-full h-full flex justify-center items-center border-2 rounded-xl' style={{
          borderColor: "#68f988",
          backgroundColor: "#80808073"
        }}>
          <AiFillCheckCircle style={{
            color: "#68f988",
            fontSize: "40px"
          }} />
        </div>
      )}
      {card && !hidden && card.suit && card.value && (
        <img src={cardUrls[`${suitMap[card.suit]}_${card.value}`]} alt='img' />
      )}
      {card && !hidden && !card.suit && !card.value && (
        <div
          className="w-full h-full rounded-lg"
        >
          <img src={cardBack} alt='img' />
        </div>
      )}
      {hidden && (
        <div
          className="w-full h-full rounded-lg"
        >
          <img src={cardBack} alt='img' />
        </div>
      )}
    </div>
  );
}
