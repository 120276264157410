import React from 'react';
import { useTimer } from 'react-timer-hook';
import { useGame } from "../../contexts/Game";

const MyTimer = ({ className, style, expiryTimestampProp }) => {
  const {
    timer
  } = useGame();
  
  if (!timer) return null
  
  let expiryTimestamp = 0;

  if (!expiryTimestampProp) {
    expiryTimestamp = new Date().getTime() + timer;
  }

  return (
    <Timer className={className} style={style} expiryTimestamp={expiryTimestampProp || expiryTimestamp} />
  );
}

const Timer = ({ expiryTimestamp, className, style }) => {
  const {
    seconds,
    minutes
  } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });


  return (
    <div className={className} style={{textAlign: 'center', ...style}}>
      <div className='flex justify-center items-center'>
        <div>{minutes < 1 ? "00" : minutes < 10 ? `0${minutes}` : minutes}</div><div>:</div><div>{seconds < 1 ? "00" : seconds < 10 ? `0${seconds}` : seconds}</div>
      </div>
    </div>
  );
}

export default MyTimer;