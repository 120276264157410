import React from "react";
import { useGame } from "../../contexts/Game";
import { indexFromPosition } from "../../utilities/position_converter";
import CountUp from "react-countup";
import boneIconImg from "./../../assets/texture/bone-icon.png";

export function Bets() {
  return (
    <div className="absolute w-full h-full pointer-events-none" style={{ top: 0 }}>
      <Pot />
      <Bet style={{ bottom: "20%", left: "50%" }} position={0} />
      <Bet style={{ bottom: "30%", left: "10%" }} position={1} />
      <Bet style={{ bottom: "70%", left: "28%" }} position={2} />
      <Bet style={{ bottom: "75%", left: "50%" }} position={3} />
      <Bet style={{ bottom: "70%", right: "28%" }} position={4} />
      <Bet style={{ bottom: "30%", right: "10%" }} position={5} />
    </div>
  );
}

function Bet({ style, position }) {
  const { seatIndex, bets, betTypes, isWatcher, MONEY_EFFECT_DURATION } = useGame();
  const index = indexFromPosition(position, isWatcher ? position : seatIndex);
  const amount = bets[index];
  const betType = betTypes[index];
  if (!betType) return null;
  return (
    <div
      className="absolute w-0 h-0 flex items-center justify-center z-20"
      style={style}
    >
      {amount > 0 && (
        <div
          className={
            "text-center text-xl w-20 h-10 flex items-center justify-center text-white mt-2"
          }
        >
          <CountUp end={amount} preserveValue={true} duration={MONEY_EFFECT_DURATION} />
          <img className='ml-2 mb-2' src={boneIconImg} width="15px" alt='img' />
        </div>
      )}
    </div>
  );
}

function Pot() {
  const { pot, MONEY_EFFECT_DURATION } = useGame();
  if (pot === 0) return null;
  return (
    <div
      className="absolute flex items-center flex-col justify-center z-20 font-balsamiq font-bold"
      style={{ bottom: "45%", left: "73%" }}
    >
      <div className='flex justify-center items-center -mb-3' style={{
        color: "#e5c23a",
        fontSize: ""
      }}>
        TOTAL POT
      </div>
      <div
        className={
          "text-center text-xl w-20 h-10 flex items-center justify-center text-white"
        }
      >
        <CountUp end={pot} preserveValue={true} duration={MONEY_EFFECT_DURATION} />
        <img className='ml-4 mb-1' src={boneIconImg} width="15px" alt='img' />
      </div>
    </div>
  );
}
